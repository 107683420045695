<template>
  <div>
    <list
      ref="refCategoryList"
      sort-by="TicketCategoriaId"
      refetch-records-name="categorias"
      key-field="TicketCategoriaId"
      :actions="actions"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchCategorias"
      :filters.sync="filters"
    />
  </div>
</template>

<script>
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import categoryService from '@/services/helpDesk.service'
import { ref } from '@vue/composition-api'

export default {
  components: {
    List,
  },
  setup() {
    const refCategoryList = ref(null)
    const {
      fetchCategorias,
    } = categoryService()
    const tableColumns = [
      {
        key: 'ticketCategoriaId',
        label: i18n.t('AppHelpDesk.categoryColumns.ticketCategoriaId'),
        sortable: true,
        sortKey: 'TicketCategoriaId',
      },
      {
        key: 'nombre',
        label: i18n.t('AppHelpDesk.categoryColumns.name'),
        sortable: false,
      },
      {
        key: 'abreviatura',
        label: i18n.t('AppHelpDesk.categoryColumns.abbreviation'),
        sortable: false,
      },
      {
        key: 'categoriaPadre.nombre',
        label: i18n.t('AppHelpDesk.categoryColumns.parentCategoria'),
        sortable: false,
      },
      {
        key: 'rutaMostrar',
        label: i18n.t('AppHelpDesk.categoryColumns.pathToShow'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'Categoria',
        routeName: 'apps-categories-edit',
        params: ['ticketCategoriaId'],
        icon: 'EditIcon',
      },
      {
        name: 'details',
        label: i18n.t('Lists.Details'),
        aclAction: 'write',
        aclResource: 'Categoria',
        routeName: 'apps-categories-users',
        params: ['ticketCategoriaId'],
        icon: 'CodesandboxIcon',
      },
    ])
    const filters = ref([])
    const actions = ref([
      {
        label: i18n.t('AppHelpDesk.newCategory'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-categories-new',
        icon: 'PlusCircleIcon',
      },
    ])
    return {
      refCategoryList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchCategorias,
    }
  },
}
</script>
